<template>
  <v-alert border="left" colored-border elevation="1" dense color="success" icon="mdi-github">
    <span>本项目开源在</span>
    <a href="https://github.com/Flysky12138/vuetify-cloudmusic" target="_blank" class="text-decoration-none">&nbsp;Github&nbsp;</a>
  </v-alert>
</template>

<script>
export default {
  data: () => ({})
}
</script>
